import React from 'react'

const ShopWallet = React.lazy(() => import('./shopwallet'))
const UserWallet = React.lazy(() => import('./userwallet'))

const routes = [
  { path: '/wallet/shopwallet', name: 'Shop Wallet', element: ShopWallet },
  { path: '/wallet/userwallet', name: 'User Wallet', element: UserWallet },
]

export default routes
