import React from 'react'

const All = React.lazy(() => import('./all'))
const Create = React.lazy(() => import('./create'))
const Trash = React.lazy(() => import('./trash'))

const routes = [
  { path: '/cms/sliders', name: 'sliders', element: All },
  { path: '/cms/slider/create', name: 'Create slider', element: Create },
  { path: '/cms/slider/:id/edit', name: 'Edit slider', element: Create },
  { path: '/cms/slider/trash', name: 'Trash gelleries', element: Trash },
]

export default routes
