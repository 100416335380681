import React, { Suspense } from 'react'
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom'
import { CContainer, CSpinner } from '@coreui/react'
// routes config
import routes from '../../c_routes'
import Cookies from 'js-cookie'
import { AlertHelper } from 'src/helpers/alertHelper'

const loading = (
  <div className="pt-3 text-center d-flex justify-content-center ">
    <h6 className="text-white">Loading </h6>
    <CSpinner color="warning" size="sm" />
  </div>
)

/**
 * Renders the content of the app.
 *
 * @return {JSX.Element} The rendered app content.
 */
const AppContent = () => {
  var token = Cookies.get('customer_auth')
  // var dispatch = useDispatch()
  // var isLogin = useSelector((state) => state.isLogin)

  // if ((token !== null || token !== '') && token !== undefined && isLogin === undefined) {
  //   dispatch({ type: 'set', isLogin: true })
  //   isLogin = true
  // }

  var navigate = useNavigate()
  const isEditMode = !!window.location.href.includes('/edit')

  return (
    <>
      <AlertHelper isEditMode={isEditMode} />
      <Suspense fallback={loading}>
        <Routes>
          {routes.map((route, idx) => {
            if (token) {
              return (
                route.element && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    element={<route.element />}
                  />
                )
              )
            } else {
              return navigate('/login')
            }
          })}

          <Route path="/" element={<Navigate to="user/dashboard" replace />} />
        </Routes>
      </Suspense>
    </>
  )
}

export default React.memo(AppContent)

/* {foduuRoutes.map((route, idx) => {
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={<route.element />}
                />
              )
            )
          })} */
