import React, { useState,useEffect } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import {
    CAlert,
    CButton,
    CCard,
    CCardBody,
    CCol,
    CContainer,
    CFormCheck,
    CInputGroup,
    CLink,
    CRow,
} from '@coreui/react'
import { useNavigate } from 'react-router-dom'
import CustomerHeader from 'src/components/customer/header'
import BasicProvider from 'src/constants/BasicProvider'
import { useDispatch, useSelector } from 'react-redux'
import CustomerFooter from 'src/components/customer/footer'

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
})

const ForgotPassword = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [mailSentSuccessful, setMailSentSuccessful] = useState(false)
    const [mailMessage, setMailMessage] = useState('')
    const badRequest = useSelector((state) => state.badRequest)




    const HandleonSubmit = async (formdata) => {
        try {
            //console.log(formdata);
            const response = await new BasicProvider(`public/customers/forgot-password`, navigate, dispatch).postRequest(formdata)
            //console.log(response)
            if (response.status === 'success') {
                setMailSentSuccessful(true)
                setMailMessage(response.data)
                // if (badRequest) setMailMessage(false)

                if (response.status === 'success') {
                    setMailSentSuccessful(true);
                    setMailMessage(response.data);
                    dispatch({ type: 'set', badRequest: '' });
              
                    setTimeout(() => {
                      setMailMessage(null);
                    }, 3000);
                  }
            }
        } catch (error) {
            console.error(error)
            dispatch({ type: 'set', badRequest: 'No user found with the provided email address' })
        }
    }

    useEffect(() => {
        if (badRequest ) {
          const timeoutId = setTimeout(() => {
            dispatch({ type: 'set', badRequest: '' });
          }, 3000);
    
          return () => clearTimeout(timeoutId);
        }
      }, [badRequest, dispatch]);
    return (
        <div className="bg-light user-background ">
            <CustomerHeader />
            <div className="min-vh-100 d-flex flex-row align-items-center w-100">
                <CContainer className="more_then_mates_Customer">
                    <CRow className="justify-content-center ">
                        <CCol md={8} lg={6} xl={5}>
                            <CCard className=" bg-theme customer-panel-card position-static">
                                <CCardBody className="p-0">
                                    <Formik
                                        initialValues={{
                                            email: '',
                                        }}
                                        validationSchema={validationSchema}
                                        onSubmit={HandleonSubmit}
                                    >
                                        <Form>
                                            <h3 className="text-center mb-4">Forgot Password</h3>
                                            {mailSentSuccessful && mailMessage !== null && <CAlert color="success">{mailMessage}</CAlert>}
                                            {badRequest && <CAlert color="danger">{badRequest}</CAlert>}
                                            <div className="my-3">
                                                <CInputGroup>
                                                    <Field
                                                        type="email"
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="email"
                                                    />
                                                </CInputGroup>
                                                <ErrorMessage name="email" component="div" className="text-danger" />


                                            </div>

                                            <div className="d-grid mb-3">
                                                <CButton type="submit" name="submit" className="sign-up">
                                                    send reset link
                                                </CButton>
                                            </div>
                                        </Form>
                                    </Formik>
                                </CCardBody>
                            </CCard>
                        </CCol>
                    </CRow>
                </CContainer>
            </div>
            <CustomerFooter />
        </div>
    )
}

export default ForgotPassword
