import React from 'react'
// CMS
import cmsBlogRoutes from './views/cms/blogs/routes'
import cmsCategoryRoutes from './views/cms/categories/routes'
import cmsContactRoutes from './views/cms/contact/routes'
import cmsGalleriesRoutes from './views/cms/galleries/routes'
import cmsNavigationRoutes from './views/cms/navigation/routes'
import cmsPagesRoutes from './views/cms/pages/routes'
import cmsSlidersRoutes from './views/cms/sliders/route'
import cmsTagRoutes from './views/cms/tags/routes'
import FaqsRoutes from './views/faqs/routes'
// import { default as routes } from './views/settings/routes'
import settingsRoutes from './views/customer-backend/settings/routes'
import exploreRoutes from './views/customer-backend/explore/route'
import locationRoutes from './views/customer-backend/location/route'
import mobileViewRoutes from './views/customer-backend/mobileview/routes'

import editProfile from './views/customer-backend/profile/routes'
// import {
//   default as CustomerRoutes,
//   default as Show,
//   default as customerEmailLog,
//   default as customerStoreVisit,
//   default as personalInfo,
// } from './views/users/customers/routes'
import Wallet from './views/wallet/routes'
// import cmsTestimonials from './views/cms/testimonials/routes'
import regionRoutes from './views/cms/region/routes'
import cmsStatusRoutes from './views/cms/status/routes'
import cmsTestimonials from './views/cms/testimonials/routes'
import EcommerceOrders from './views/ecommerce/orders/routes'
import ProductRoutes from './views/ecommerce/products/routes'
// import {
//   default as adminLogsRoutes,
//   default as changePassword,
//   default as emailLog,
//   default as mailLogsRoutes,
//   default as mailShowRoutes,
//   default as storeVisit,
// } from './views/users/alllogs/routes'
// import usersRoutes from './views/users/users/routes'
import CustomersRoutes from './views/admin/customers/routes'
import Allpackage from './views/customer-backend/package/routes'
import Allnotification from './views/customer-backend/notification/routes'
import AllBlockedUser from './views/customer-backend/blockeduser/route'
import AllMatch from './views/customer-backend/match/routes'
// import AllMessage from './views/customer-backend/message/routes'

// import PagesRoutes from './views/pages/customer-auth/routes'

// import {
//   default as emailLogs,
//   default as password,
//   default as personalInformation,
//   default as showUserRoutes,
//   default as store,
// } from './views/users/users/routes'

// import ShowUnassigned from './views/sim/ShowUnassigned'

// const Dashboard = React.lazy(() => import('./views/customer-backend/dashboard/Dashboard'))
const Dashboard = React.lazy(() => import('./views/customer-backend/dashboard/Dashboard'))

const Widgets = React.lazy(() => import('./views/widgets/Widgets'))

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/user/dashboard', name: 'Customer Dashboard', element: Dashboard },
  { path: '/widgets', name: 'Widgets', element: Widgets },
  // {
  //   path: 'cms//users/:id/password', // Define the URL pattern, use :id as a placeholder
  //   name: 'Change Password', // The name of the route (displayed in navigation, etc.)
  //   element: <chngPassword />, // The component to render when navigating to this route
  // },
  ...cmsPagesRoutes,
  ...cmsBlogRoutes,
  ...cmsNavigationRoutes,
  ...cmsTestimonials,
  ...cmsGalleriesRoutes,
  ...cmsSlidersRoutes,
  ...cmsContactRoutes,
  ...cmsCategoryRoutes,
  ...cmsTagRoutes,
  ...FaqsRoutes,
  // ...CustomerRoutes,
  ...ProductRoutes,
  // ...usersRoutes,
  ...EcommerceOrders,
  ...regionRoutes,
  ...cmsStatusRoutes,
  // ...adminLogsRoutes,
  // ...mailLogsRoutes,
  // ...mailShowRoutes,
  // ...showUserRoutes,
  // ...mailLogsRoutes,
  // ...personalInformation,
  // ...changePassword,
  // ...emailLog,
  // ...storeVisit,
  // ...password,
  // ...Show,
  // ...personalInfo,
  // ...customerStoreVisit,
  // ...customerEmailLog,
  // ...emailLogs,
  // ...store,
  ...settingsRoutes,
  ...Wallet,
  ...CustomersRoutes,
  ...Allpackage,
  ...exploreRoutes,
  ...locationRoutes,
  ...editProfile,
  ...Allnotification,
  // ...PagesRoutes,
  ...AllMatch,
  // ...AllMessage,
  ...mobileViewRoutes,
  ...AllBlockedUser,
]

export default routes
