import React, { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import MultiRangeSlider from 'multi-range-slider-react'
import PropTypes from 'prop-types'

import { CBadge, CFormSwitch } from '@coreui/react'
import RangeSlider from '../custom/RangeSlider'
import BasicProvider from 'src/constants/BasicProvider'
import { setAlertTimeout } from 'src/helpers/alertHelper'
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBolt, faHeartCirclePlus, faStar } from '@fortawesome/free-solid-svg-icons'

export const AppSidebarNav = ({ items }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const data = useSelector((state) => state.data)
  const [minValue, setMinValue] = useState()
  const [maxValue, setMaxValue] = useState()
  const [pendingUpdate, setPendingUpdate] = useState(false)
  // const [boost, setBoost] = useState('')
  const boost = useSelector((state) => state.boost)
  const superLike = useSelector((state) => state.superlike)

  useEffect(() => {
    fetchData()
    // //console.log("here");
  }, [dispatch])
  const fetchData = async () => {
    try {
      const response = await new BasicProvider(`public/customers/profile`).getRequest()
      const { min_age_preference, max_age_preference } = response.data

      setMinValue(min_age_preference || 18)
      setMaxValue(max_age_preference || 50)
      dispatch({ type: 'set', data: response.data })

      const boost = await new BasicProvider(`public/boost/only-boost`).getRequest()
      dispatch({ type: 'set', boost: boost.data })

      const superLike = await new BasicProvider(`public/superlikes/only-superlikes`).getRequest()
      // console.log(superLike)
      dispatch({ type: 'set', superlike: superLike.data })
      if (superLike.data !== '0') dispatch({ type: 'set', canSwipe: true })
    } catch (error) {
      console.error('Error:', error)
      // Handle the error, e.g., set a default profile or show an error message
    }
  }

  // //console.log('maxValue:', maxValue)
  useEffect(() => {
    let timerId
    setPendingUpdate(true)

    if (pendingUpdate) {
      timerId = setTimeout(() => {
        const data = {
          min_age_preference: minValue,
          max_age_preference: maxValue,
        }

        // //console.log('Data to Send ', data)

        const submitData = async () => {
          try {
            const response = await new BasicProvider(`public/customers/profileUpdate`).patchRequest(
              data,
            )
            // //console.log(response)
          } catch (error) {
            console.log(error)
          } finally {
            setPendingUpdate(false)
          }
        }

        submitData()
      }, 3000)
    }

    return () => clearTimeout(timerId)
  }, [minValue, maxValue])

  const navLink = (name, icon, badge) => {
    return (
      <>
        {icon && icon}
        {name && name}
        {badge && (
          <CBadge color={badge.color} className="ms-auto">
            {badge.text}
          </CBadge>
        )}
      </>
    )
  }
  const navItem = (item, index) => {
    const { component, name, badge, icon, ...rest } = item
    const Component = component

    const handleSliderChange = (min, max) => {
      setMinValue(min)
      setMaxValue(max)
    }

    return (
      <React.Fragment key={index}>
        {icon === 'range' ? (
          // <div className="px-3 range_block py-3">
          //   <div className="d-flex justify-content-between mb-3">
          //     {name} <div>18 -{rangeValue}</div>
          //   </div>
          //   <input
          //     type="range"
          //     className="form-range age-range"
          //     min="18"
          //     max="100"
          //     id="customRange2"
          //     value={rangeValue}
          //     onChange={handleRangeChange}
          //   />
          // </div>
          <div className="px-3 range_block py-4">
            <div className="d-flex justify-content-between mb-3">
              {name}
              <div className="mb-2">
                {minValue} - {maxValue}
              </div>
            </div>
            <RangeSlider
              minValue={minValue}
              maxValue={maxValue}
              onSliderChange={handleSliderChange}
            />
          </div>
        ) : null}
        {icon === 'counts' ? (
          <div className="row text-center mt-5 mb-3 feature_count_blocks px-2">
            <div className="col-6">
              <div className="card py-4">
                <h6 className="mb-0 ">{boost ? boost : 0} remaining</h6>
                <div className="icon_bg">
                  <FontAwesomeIcon className="boost_para" icon={faBolt} />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="card py-4">
                <h6 className="mb-0 ">{superLike ? superLike : 0} remaining</h6>
                <div className="icon_bg">
                  <FontAwesomeIcon className="superlike_para" icon={faStar} />
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {icon === 'switch' ? (
          <div className="d-flex justify-content-between align-items-center nav-link switch_block">
            <div>{name}</div>
            <CFormSwitch id={`formSwitchCheckChecked${index}`} className="sidebar-form-check" />
          </div>
        ) : null}
        {icon !== 'switch' && icon !== 'counts' && icon !== 'range' ? (
          <Component
            {...(rest.to &&
              !rest.items && {
                component: NavLink,
              })}
            key={index}
            {...rest}
          >
            {navLink(name, icon, badge)}
          </Component>
        ) : null}
      </React.Fragment>
    )
  }

  const navGroup = (item, index) => {
    const { component, name, icon, to, ...rest } = item
    const Component = component

    return (
      <Component
        idx={String(index)}
        key={index}
        toggler={navLink(name, icon)}
        visible={location.pathname.startsWith(to)}
        {...rest}
      >
        {item.items?.map((item, subIndex) =>
          item.items ? navGroup(item, subIndex) : navItem(item, subIndex),
        )}
      </Component>
    )
  }

  return (
    <React.Fragment>
      {items &&
        items.map((item, index) => (item.items ? navGroup(item, index) : navItem(item, index)))}
    </React.Fragment>
  )
}

AppSidebarNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
}

// Enable Discovery

// import React, { useEffect, useState } from 'react'
// import { NavLink, useLocation } from 'react-router-dom'
// import MultiRangeSlider from 'multi-range-slider-react'
// import PropTypes from 'prop-types'

// import { CBadge, CFormSwitch } from '@coreui/react'
// import RangeSlider from '../custom/RangeSlider'
// import BasicProvider from 'src/constants/BasicProvider'
// import { setAlertTimeout } from 'src/helpers/alertHelper'
// import { useSelector, useDispatch } from 'react-redux'

// export const AppSidebarNav = ({ items }) => {
// const dispatch = useDispatch()
// const location = useLocation()
// const data = useSelector((state) => state.data)
// const [minValue, setMinValue] = useState(18)
// const [maxValue, setMaxValue] = useState(50)
// const [pendingUpdate, setPendingUpdate] = useState(false)
// const [isSwitchOn, setIsSwitchOn] = useState(false) // Initialize with the default value

// useEffect(() => {
// fetchData()
// }, [])

// const fetchData = async () => {
// try {
// const response = await new BasicProvider(`public/customers/profile`).getRequest()
// const { min_age_preference, max_age_preference, enable_discovery } = response.data

// if (enable_discovery !== undefined && enable_discovery != '') {
// setIsSwitchOn(enable_discovery)
// }
// if (min_age_preference !== undefined && max_age_preference !== undefined) {
// setMinValue(min_age_preference)
// setMaxValue(max_age_preference)
// }
// dispatch({ type: 'set', data: response.data })
// } catch (error) {
// console.error('Error:', error)
// // Handle the error, e.g., set a default profile or show an error message
// }
// }
// useEffect(() => {
// let timerId
// setPendingUpdate(true)

// if (pendingUpdate) {
// timerId = setTimeout(() => {
// const data = {
// min_age_preference: minValue,
// max_age_preference: maxValue,
// }

// // //console.log('Data to Send ', data)

// const submitData = async () => {
// try {
// const response = await new BasicProvider(`public/customers/profileUpdate`).patchRequest(
// data,
// )

// //console.log(response)
// } catch (error) {
// //console.log(error)
// } finally {
// setPendingUpdate(false)
// }
// }

// submitData()
// }, 2000)
// }

// return () => clearTimeout(timerId)
// }, [minValue, maxValue])

// useEffect(() => {
// const data = {
// enable_discovery: isSwitchOn,
// }
// const submitData = async () => {
// try {
// const response = await new BasicProvider(`public/customers/profileUpdate`).patchRequest(
// data
// )
// //console.log(response)
// } catch (error) {
// //console.log(error)
// } finally {
// setPendingUpdate(false)
// }
// }
// submitData()
// }, [isSwitchOn])

// //handle Switch
// const handleSwitchChange = () => {
// setIsSwitchOn((prevValue) => !prevValue) // Toggle the switch state
// }
// //console.log('Min Values', minValue)
// const navLink = (name, icon, badge) => {
// return (
// <>
// {icon && icon}
// {name && name}
// {badge && (
// <CBadge color={badge.color} className="ms-auto">
// {badge.text}
// </CBadge>
// )}
// </>
// )
// }
// const navItem = (item, index) => {
// const { component, name, badge, icon, ...rest } = item
// const Component = component

// const handleSliderChange = (min, max) => {
// setMinValue(min)
// setMaxValue(max)
// }

// return (
// <React.Fragment key={index}>
// {icon === 'range' ? (
// // <div className="px-3 range_block py-3">
// // <div className="d-flex justify-content-between mb-3">
// // {name} <div>18 -{rangeValue}</div>
// // </div>
// // <input
// // type="range"
// // className="form-range age-range"
// // min="18"
// // max="100"
// // id="customRange2"
// // value={rangeValue}
// // onChange={handleRangeChange}
// // />
// // </div>
// <div className="px-3 range_block py-4">
// <div className="d-flex justify-content-between mb-3">
// {name}
// <div className="mb-2">
// {minValue} - {maxValue}
// </div>
// </div>
// <RangeSlider
// minValue={minValue}
// maxValue={maxValue}
// onSliderChange={handleSliderChange}
// />
// </div>
// ) : null}
// {icon === 'switch' ? (
// <div className="d-flex justify-content-between align-items-center nav-link switch_block">
// <div>{name}</div>
// <CFormSwitch
// id={`formSwitchCheckChecked${index}`}
// className="sidebar-form-check"
// onChange={handleSwitchChange} // Add onChange event to update the state
// checked={isSwitchOn}
// />
// {/* <div className="mt-2">
// <p className="mb-0">
// When turned off, your profile will be hidden from the card stack, and Discovery will
// be disabled. People you have already liked may still see you and match with you.
// </p>
// </div> */}
// </div>
// ) : null}
// {icon !== 'switch' && icon !== 'range' ? (
// <Component
// {...(rest.to &&
// !rest.items && {
// component: NavLink,
// })}
// key={index}
// {...rest}
// >
// {navLink(name, icon, badge)}
// </Component>
// ) : null}
// </React.Fragment>
// )
// }

// const navGroup = (item, index) => {
// const { component, name, icon, to, ...rest } = item
// const Component = component

// return (
// <Component
// idx={String(index)}
// key={index}
// toggler={navLink(name, icon)}
// visible={location.pathname.startsWith(to)}
// {...rest}
// >
// {item.items?.map((item, subIndex) =>
// item.items ? navGroup(item, subIndex) : navItem(item, subIndex),
// )}
// </Component>
// )
// }

// return (
// <React.Fragment>
// {items &&
// items.map((item, index) => (item.items ? navGroup(item, index) : navItem(item, index)))}
// </React.Fragment>
// )
// }

// AppSidebarNav.propTypes = {
// items: PropTypes.arrayOf(PropTypes.any).isRequired,
// }
