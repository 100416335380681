import React from 'react'

const All = React.lazy(() => import('./all'))
const Create = React.lazy(() => import('./create'))
const Trash = React.lazy(() => import('./trash'))
const Show = React.lazy(() => import('./Show'))
const changePassword = React.lazy(() => import('./changePassword'))
const personalInfo = React.lazy(() => import('./personalInfo'))
const customerEmailLog = React.lazy(() => import('./emailLog'))
const customerStoreVisit = React.lazy(() => import('./storeVisit'))
const customerCommission = React.lazy(() => import('./shopcommission'))
const wallet = React.lazy(() => import('./wallet'))
const simStock = React.lazy(() => import('./simStock'))
const simHistory = React.lazy(() => import('./simHistory'))
const orders = React.lazy(() => import('./orders'))
const Analytics = React.lazy(() => import('./analytics'))
const packageExpiry = React.lazy(() => import('./package-expiry'))
const boostUsers = React.lazy(() => import('./boostUsers'))
const showEmail = React.lazy(() => import('./showEmail'))
const ReportedUserData = React.lazy(() => import('./reportedUser'))
const BlokedUserData = React.lazy(() => import('./blokedUser'))

// const Add = React.lazy(() => import('./addfaq'))

const routes = [
  { path: '/cms/customers/all', name: 'customers', element: All },
  { path: '/cms/customers/trash', name: 'Trash customers', element: Trash },
  { path: '/cms/customers/create', name: 'Add customers', element: Create },
  { path: '/cms/customers/:id/edit', name: 'Edit customers', element: Create },
  { path: '/cms/customers/:id/Show', name: 'Show Customers', element: Show },
  { path: '/cms/customers/:id/changePassword', name: 'Change Password', element: changePassword },
  { path: '/cms/customers/:id/info', name: 'Personal Info', element: personalInfo },
  { path: '/cms/customers/:id/emailLog', name: 'Email Log', element: customerEmailLog },
  { path: '/cms/customers/:id/storeVisit', name: 'Store Visit', element: customerStoreVisit },
  {
    path: '/cms/customers/:id/shopcommission',
    name: 'Shop Commission',
    element: customerCommission,
  },
  { path: '/cms/customers/:id/wallet', name: 'Wallet', element: wallet },
  { path: '/cms/customers/:id/simStock', name: 'Sim Stock', element: simStock },
  { path: '/cms/customers/:id/simHistory', name: 'Sim History', element: simHistory },
  { path: '/cms/customers/:id/orders', name: 'Orders', element: orders },

  { path: '/cms/customers/:id/Show', name: 'Show Customers', element: Show },

  { path: '/cms/customers/:id/analytics', name: 'Analytics Costomer', element: Analytics },
  { path: '/cms/customers/packageExpiry', name: 'Packege Expiry', element: packageExpiry },
  { path: '/cms/customers/boostUsers', name: 'Boost Users', element: boostUsers },
  { path: '/cms/customers/:id/showEmail', name: 'Show content', element: showEmail },
  { path: '/cms/customers/:id/reportedUser', name: 'Reported User ', element: ReportedUserData },
  { path: '/cms/customers/:id/blokedUser', name: 'Blocked User ', element: BlokedUserData },
  //   { path: '/cms/page/edit', name: 'Edit Pages', element: Create },
]

export default routes
