import React from 'react'

const All = React.lazy(() => import('./all'))
const Create = React.lazy(() => import('./create'))
const Trash = React.lazy(() => import('./trash'))
const OrderDetails = React.lazy(() => import('./orderdetails'))

const routes = [
  { path: '/orders/all', name: 'Orders', element: All },
  // { path: '/ecommerce/page/newcreate', name: 'Create Check-in', element: newCreate },
  { path: '/orders/create', name: 'Create Orders', element: Create },
  { path: '/orders/:id/edit', name: 'Edit Orders', element: Create },
  { path: '/orders/trash', name: 'Trash Orders', element: Trash },
  { path: '/orders/orderdetails/:id', name: 'Orders Details', element: OrderDetails },
]

export default routes
