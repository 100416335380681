import React from 'react'
import { CFooter, CNavLink } from '@coreui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHeartCirclePlus,
  faHome,
  faMessage,
  faSearch,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom'

const AppFooter = () => {
  return (
    <CFooter>
      <div className="d-flex justify-content-between">
        <NavLink to="/user/dashboard">
          <FontAwesomeIcon icon={faHome} />
        </NavLink>
        <NavLink to="/user/explore">
          <FontAwesomeIcon icon={faSearch} />
        </NavLink>
        <NavLink to="/user/match">
          <FontAwesomeIcon icon={faHeartCirclePlus} />
        </NavLink>
        <NavLink to="/message">
          <FontAwesomeIcon icon={faMessage} />
        </NavLink>
        <NavLink to="/user/profile">
          <FontAwesomeIcon icon={faUserCircle} />
        </NavLink>
      </div>
    </CFooter>
  )
}

export default React.memo(AppFooter)
