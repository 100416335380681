// components/Header.js
import React, { useState } from 'react'
// import sidebarlogo from '../assets/images/morethenmates.png'
import sidebarlogo from '../../assets/images/morethenmates.png'
import {
  CButton,
  CCloseButton,
  CCol,
  CCollapse,
  CContainer,
  CDropdown,
  CDropdownDivider,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CForm,
  CFormInput,
  CHeader,
  CHeaderBrand,
  CHeaderNav,
  CHeaderToggler,
  CNavItem,
  CNavLink,
  CNavbar,
  CNavbarBrand,
  CNavbarNav,
  CNavbarToggler,
  COffcanvas,
  COffcanvasBody,
  COffcanvasHeader,
  COffcanvasTitle,
  CRow,
} from '@coreui/react'
import { a } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faUser, faUsers, faXmark } from '@fortawesome/free-solid-svg-icons'
import logo1 from '../../assets/images/contact/logo1.png'
import logo from '../../assets/images/favicon.png'

const CustomerFooter = () => {
  const [isMenuOpen, setMenuOpen] = useState(false)

  const toggleMenu = () => {
    // console.log("Toggle menu!");
    setMenuOpen(!isMenuOpen)
  }

  return (
    <>
      <footer className="footer-section bg-theme">
        <div className="footer-bottom">
          <CContainer fluid>
            <div className="row align-items-center py-2">
              <CCol lg={6}>
                <div className="footer-bottom-content text-center white">
                  <p className="mb-0">
                    &copy;2023 More Than Mates designed by website design company
                    <a href="#"> FODUU</a>.
                  </p>
                </div>
              </CCol>
              <CCol lg={6} className="float-end">
                <ul className="d-flex footer-link white mb-0 justify-content-center ps-0">
                  <li>
                    <a href="/#/privacy-policy">Privacy</a>
                  </li>
                  <li>
                    <a href="/#/terms-of-service">Terms & Condition</a>
                  </li>
                  <li>
                    <a href="/#/cookie-policy">Cookie Policy</a>
                  </li>
                  {/* <li>
                    <a href="/#/intellectual-property" >
                      Intellectual Property
                    </a>
                  </li> */}
                </ul>
              </CCol>
            </div>
          </CContainer>
        </div>
      </footer>
    </>
  )
}

export default CustomerFooter
