import { useEffect, useState } from 'react'

import MultiRangeSlider from 'multi-range-slider-react'
const RangeSlider = ({ minValue, maxValue, onSliderChange }) => {
  // //console.log('minValue',minValue);
  return (
    <MultiRangeSlider
      min={18}
      max={100}
      step={5}
      minValue={minValue}
      maxValue={maxValue}
      onInput={(e) => {
        onSliderChange(e.minValue, e.maxValue)
      }}
    />
  )
}

export default RangeSlider
