// components/Header.js
import { useState } from 'react'
// import sidebarlogo from '../assets/images/morethenmates.png'
// import sidebarlogo from '../../assets/images/morethenmates.png'
import {
  faBars,
  faMinus,
  faPlus,
  faUser,
  faUsers,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Cookies from 'js-cookie'
import logo1 from '../../assets/images/contact/logo1.png'
import sidebarlogo from '../../assets/images/favicon.ico'
import { NavLink } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import {
  CCollapse,
  CContainer,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CNavItem,
  CNavLink,
  CNavbar,
  CNavbarBrand,
  CNavbarNav,
  CNavbarToggler,
} from '@coreui/react'

const CustomerHeader = () => {
  const [isMenuOpen, setMenuOpen] = useState(false)
  const [visible, setVisible] = useState(false)
  const [Submenu1, setSubmenu1] = useState({ Products: false, Safety: false })
  const navigate = useNavigate()
  const toggleMenu = () => {
    // console.log("Toggle menu!");
    setSubmenu1({ Products: false, Safety: false })
    setMenuOpen(!isMenuOpen)
  }
  const activeSubmenu1 = (SubmenuBox) => {
    setSubmenu1((prevState) => ({
      ...prevState,
      [SubmenuBox]: !prevState[SubmenuBox],
    }))
    // console.log('ds;fj', Submenu1)
  }
  const RouterRedirect = (param) => {}
  const token = Cookies.get('customer_auth')
  // console.log(token)

  return (
    <>
      <header className="header-section">
        <div className="header-bottom">
          <div className="container">
            <div className="header-wrapper d-flex justify-content-between align-items-center">
              <div className="logo">
                <a href={`${process.env.REACT_APP_MAIN_URL}`}>
                  <img
                    src={logo1}
                    height={68}
                    width={322}
                    alt="logo"
                    className="d-none d-sm-inline"
                  />
                </a>
                <a href={`${process.env.REACT_APP_MAIN_URL}`}>
                  <img src={sidebarlogo} height={68} alt="logo" className="d-inline d-sm-none" />
                </a>
              </div>

              <div className="menu-area d-flex ">
                <ul className={`menu mb-0  ${isMenuOpen ? 'active' : ''} `}>
                  <li>
                    <NavLink to={`${process.env.REACT_APP_MAIN_URL}`}>Home</NavLink>
                  </li>

                  <li>
                    <NavLink
                      to="javascript:;"
                      onClick={() => activeSubmenu1('Products')}
                      className="Mobile_submenu"
                    >
                      Products
                      <div className="d-none d-ms-block">
                        {Submenu1.Products ? (
                          <FontAwesomeIcon icon={faPlus} />
                        ) : (
                          <FontAwesomeIcon icon={faMinus} />
                        )}
                      </div>
                    </NavLink>

                    <ul className={`submenu ${Submenu1.Products ? 'd-block' : 'sumenu_none '}`}>
                      <li>
                        <NavLink to={`${process.env.REACT_APP_MAIN_URL}/subscription-tiers`}>
                          Subscription Tiers
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <NavLink
                      to="javascript:;"
                      onClick={() => activeSubmenu1('Safety')}
                      className="Mobile_submenu"
                    >
                      Safety
                      <div className="d-none d-ms-block">
                        {Submenu1.Safety ? (
                          <FontAwesomeIcon icon={faPlus} />
                        ) : (
                          <FontAwesomeIcon icon={faMinus} />
                        )}
                      </div>
                    </NavLink>

                    <ul className={`submenu ${Submenu1.Safety ? 'd-block' : 'sumenu_none '}`}>
                      <li>
                        <a href="/#/community-guidelines" onClick={toggleMenu}>
                          Community Guidelines
                        </a>
                      </li>
                      <li>
                        <a href="/#/safety-tips" onClick={toggleMenu}>
                          Safety Tips
                        </a>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <a href="/#/help-and-support">Contact</a>
                  </li>
                  <li>
                    <a href={`${process.env.REACT_APP_MAIN_URL}/blog`}>Blogs</a>
                  </li>
                </ul>
              </div>
              <div className="menu-area mms-22 d-flex align-items-center">
                {!token && (
                  <div className="mobile_screen_btn">
                    <div className="desktop_screen_btn ">
                      <a
                        href="/#/login"
                        className="signup bg-pink"
                        style={{ borderRadius: '2px', padding: '8px 15px ' }}
                      >
                        <FontAwesomeIcon className="font-icons" icon={faUser} />
                        <span className=""> LOG IN</span>
                      </a>
                      <a
                        href="/#/register"
                        className="signup ms-2 bg-pink"
                        style={{ borderRadius: '2px', padding: '8px 15px ' }}
                      >
                        <FontAwesomeIcon className="font-icons" icon={faUsers} />
                        <span> SIGN UP</span>
                      </a>
                    </div>
                    <div className="mobile_btn">
                      <a
                        href={`${process.env.NEXT_PUBLIC_APP_URL}`}
                        className="signup "
                        style={{
                          borderRadius: '2px',
                          padding: '8px 15px',
                          backgroundColor: '#df314d',
                        }}
                      >
                        <FontAwesomeIcon className="font-icons" icon={faUser} />
                        <span className=""> Login/Signup </span>
                      </a>
                    </div>
                  </div>
                )}

                <div className="menu-toggle" onClick={toggleMenu}>
                  {/* <div className="header-bar d-lg-none ms-2"> */}
                  <div
                    className={`header-bar d-lg-none ms-2 ${isMenuOpen ? 'active' : ''}`}
                    onClick={toggleMenu}
                  >
                    {/* <span></span>
                    <span></span>
                    <span></span> */}
                    {isMenuOpen ? (
                      <FontAwesomeIcon icon={faXmark} className="white" />
                    ) : (
                      <FontAwesomeIcon icon={faBars} className="white" />
                    )}
                  </div>
                </div>
                <div className="ellepsis-bar d-lg-none">
                  <i className="icofont-info-square"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default CustomerHeader
