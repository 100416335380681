import React from 'react'

const editprofile = React.lazy(() => import('./editprofile'))
const profile = React.lazy(() => import('./profile'))

const routes = [
  { path: '/user/profile/editprofile', name: 'editprofile', element: editprofile },
  { path: '/user/profile', name: 'profile', element: profile },
]

export default routes
