import React from 'react'

const All = React.lazy(() => import('./all'))
const Create = React.lazy(() => import('./create'))
const Trash = React.lazy(() => import('./trash'))

const routes = [
  { path: '/cms/blogs', name: 'Blogs', element: All },
  { path: '/cms/blog/create', name: 'Create Blog', element: Create },
  { path: '/cms/blog/:id/edit', name: 'Edit Blog', element: Create },
  { path: '/cms/blog/trash', name: 'Trash Blogs', element: Trash },
]

export default routes
