import 'react-app-polyfill/stable'
import 'core-js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './store'
import { StyleSheetManager } from 'styled-components'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'

const shouldForwardProp = (prop) => prop !== 'sortDirection'
const initialOptions = {
  clientId: process.env.REACT_APP_CLIENT_ID,
  currency: process.env.REACT_APP_CURRENCY,
  debug: false,
  intent: 'capture',
}
createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <PayPalScriptProvider options={initialOptions}>
        <App />
      </PayPalScriptProvider>
    </StyleSheetManager>
  </Provider>,
)

reportWebVitals()
