import React from 'react'

const All = React.lazy(() => import('./all'))
const Trash = React.lazy(() => import('./trash'))
const Show = React.lazy(() => import('./show'))

const routes = [
  { path: '/cms/contacts', name: 'Contacts', element: All },
  { path: '/cms/contact/trash', name: 'Trash Contacts', element: Trash },
  { path: '/cms/contact/:id/show', name: 'Show Contacts', element: Show },
  // { path: '/cms/contact/:id/edit', name: 'Edit Contacts', element: Create },
]

export default routes
