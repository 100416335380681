import React from 'react'

const All = React.lazy(() => import('./all'))
const Create = React.lazy(() => import('./create'))
const Trash = React.lazy(() => import('./trash'))

const routes = [
  { path: '/products/all', name: 'Products', element: All },
  { path: '/products/trash', name: 'Trash Products', element: Trash },
  { path: '/products/create', name: 'Add Products', element: Create },
  { path: '/products/:id/edit', name: 'Edit Products', element: Create },
]

export default routes
