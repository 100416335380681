import React, { useState } from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import BasicProvider from 'src/constants/BasicProvider'
import {
  CButton,
  CFormInput,
  CInputGroup,
  CContainer,
  CRow,
  CCol,
  CCard,
  CCardBody,
} from '@coreui/react'

const ResetPassword = () => {
  const [resetSent, setResetSent] = useState(false) // State variable to track if reset email is sent
  const [resetMessage, setResetMessage] = useState('')
  const handleSubmit = async (values) => {
    try {
      const response = await new BasicProvider('auth/forgot-password').postRequest(values)
      //console.log(response)
      setResetMessage(response.data)
      if (response) {
        setResetSent(true) // Set the state variable to indicate reset email sent
      }
    } catch (error) {
      console.error(error)
    }
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
  })

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={9} lg={7} xl={6}>
            <CCard className="mx-4">
              <CCardBody className="p-4">
                <Formik
                  initialValues={{
                    email: '',
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  <Form>
                    <p className="text-medium-emphasis">Email</p>
                    <CInputGroup className="mb-3">
                      <Field
                        type="text"
                        name="email"
                        placeholder="enter valid email"
                        autoComplete="email"
                        a
                        as={CFormInput}
                      />
                      <ErrorMessage name="email" component="div" className="text-danger" />
                    </CInputGroup>
                    <div className="d-grid">
                      <CButton color="success" type="submit">
                        reset password
                      </CButton>
                    </div>
                  </Form>
                </Formik>
                {resetSent && <div className="alert alert-success mt-3">{resetMessage}</div>}
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default ResetPassword
