import React from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CFormCheck,
  CInputGroup,
  CInputGroupText,
  CLink,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import BasicProvider from 'src/constants/BasicProvider'
import { useNavigate, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyBill1Wave } from '@fortawesome/free-solid-svg-icons'
import CustomerHeader from 'src/components/customer/header'

const validationSchema = Yup.object().shape({
  password: Yup.string().required('Password is required'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
})

const CustomerSetPassword = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const HandleonSubmit = async (formdata) => {
    try {
      //console.log(formdata);
      const data = await new BasicProvider(`public/customers/reset-password/${id}`).postRequest(
        formdata,
      )
      // console.log(data);
      if (data.status === 'success') navigate('/login')
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <div className="bg-light user-background ">
      <CustomerHeader />
      <div className="min-vh-70 d-flex flex-row align-items-center w-100">
        <CContainer className="more_then_mates_Customer">
          <CRow className="justify-content-center ">
            <CCol md={8} lg={6} xl={5}>
              <CCard className=" bg-theme customer-panel-card position-static">
                <CCardBody className="p-0">
                  <Formik
                    initialValues={{
                      password: '',
                      confirm_password: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={HandleonSubmit}
                  >
                    <Form>
                      <h3 className="text-center mb-4">Set Password</h3>
                      <div className="my-3">
                        <CInputGroup>
                          <Field
                            type="password"
                            name="password"
                            className="form-control"
                            placeholder="password"
                          />
                        </CInputGroup>
                        <ErrorMessage name="password" component="div" className="text-danger" />

                        <CInputGroup>
                          <Field
                            type="password"
                            name="confirm_password"
                            className="form-control"
                            placeholder="repeat password"
                          />
                        </CInputGroup>
                        <ErrorMessage
                          name="confirm_password"
                          component="div"
                          className="text-danger"
                        />
                      </div>

                      <div className="d-grid mb-3">
                        <CButton type="submit" name="submit" className="sign-up">
                          Send
                        </CButton>
                      </div>
                    </Form>
                  </Formik>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </div>
  )
}

export default CustomerSetPassword
