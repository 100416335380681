import React from 'react'

const All = React.lazy(() => import('./all'))
const Create = React.lazy(() => import('./create'))
// const Trash = React.lazy(() => import('./trash'))

const routes = [
  { path: '/cms/testimonials', name: 'testimonials', element: All },
  { path: '/cms/testimonial/create', name: 'Create testimonial', element: Create },
  { path: '/cms/testimonial/:id/edit', name: 'Edit testimonials', element: Create },
  // { path: '/cms/testimonial/trash', name: 'Trash testimonials', element: Trash },
]

export default routes
