import React from 'react'

const All = React.lazy(() => import('./all'))
const Create = React.lazy(() => import('./create'))
const Trash = React.lazy(() => import('./trash'))

const routes = [
  { path: '/faqs/all', name: 'faqs', element: All },
  { path: '/faqs/trash', name: 'Trash FAQs', element: Trash },
  { path: '/faqs/create', name: 'Add FAQs', element: Create },
  { path: '/faqs/:id/edit', name: 'Edit Pages', element: Create },
]

export default routes
