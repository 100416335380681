import React from 'react'

const All = React.lazy(() => import('./all'))
const Create = React.lazy(() => import('./create'))
const Trash = React.lazy(() => import('./trash'))

const routes = [
  { path: '/cms/galleries', name: 'galleries', element: All },
  { path: '/cms/galleries/create', name: 'Create galleries', element: Create },
  { path: '/cms/galleries/:id/edit', name: 'Edit galleries', element: Create },
  { path: '/cms/galleries/trash', name: 'Trash galleries', element: Trash },
]

export default routes
