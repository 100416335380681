import {
  cilAccountLogout,
  cilAddressBook,
  cilArrowCircleRight,
  cilArrowRight,
  cilCart,
  cilCheckCircle,
  cilChevronCircleRightAlt,
  cilChevronRight,
  cilCog,
  cilCommentSquare,
  cilCursor,
  cilDescription,
  cilExternalLink,
  cilImagePlus,
  cilLayers,
  cilLibraryAdd,
  cilLink,
  cilLocationPin,
  cilLockLocked,
  cilPuzzle,
  cilSpeedometer,
  cilTags,
  cilUser,
  cilUserPlus,
  cilVoiceOverRecord,
  cilWallet,
} from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react'
import AuthHelpers from './helpers/AuthHelpers'

const _customernav = [
  {
    component: CNavItem,
    name: 'Age preference',
    icon: 'counts',
  },
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/user/dashboard',
    icon: <CIcon icon={cilChevronCircleRightAlt} customClassName="nav-icon" />,
    // badge: {
    //   color: 'info',
    //   text: 'NEW',
    // },
  },
  {
    component: CNavItem,
    name: 'Package',
    to: '/user/package',
    icon: <CIcon icon={cilChevronCircleRightAlt} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Notifcations',
    to: '/user/notification',
    icon: <CIcon icon={cilChevronCircleRightAlt} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Explore',
    to: '/user/explore',
    icon: <CIcon icon={cilChevronCircleRightAlt} customClassName="nav-icon" />,
  },

  {
    component: CNavItem,
    name: 'Profile',
    to: '/user/profile',
    icon: <CIcon icon={cilChevronCircleRightAlt} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Blocked User',
    to: '/user/blockeduser',
    icon: <CIcon icon={cilChevronCircleRightAlt} customClassName="nav-icon" />,
  },
  // {
  //   component: CNavItem,
  //   name: 'Match',
  //   to: '/user/match',
  //   icon: <CIcon icon={cilChevronCircleRightAlt} customClassName="nav-icon" />,
  // },
  {
    component: CNavItem,
    name: 'Messages',
    to: '/message',
    icon: <CIcon icon={cilChevronCircleRightAlt} customClassName="nav-icon" />,
  },
  // {
  //   component: CNavItem,
  //   name: 'Messagestest',
  //   to: '/user/customchat',
  //   icon: <CIcon icon={cilChevronCircleRightAlt} customClassName="nav-icon" />,
  // },

  {
    component: CNavItem,
    name: 'Location',
    to: '/user/location',
    icon: <CIcon icon={cilChevronCircleRightAlt} customClassName="nav-icon" />,
  },
  // {
  //   component: CNavTitle,
  //   name: 'Enable Discovery',
  //   icon: 'switch',
  // },
  {
    component: CNavItem,
    name: 'Age preference',
    icon: 'range',
  },

  // {
  //   component: CNavItem,
  //   name: 'Store Settings',
  //   to: '/settings/all',
  //   icon: <CIcon icon={cilCog} customClassName="nav-icon" />,
  // },
  // {
  //   component: CNavGroup,
  //   name: 'Settings',
  //   to: '/settings',
  //   // icon: <CIcon icon={cilCheckCircle} customClassName="nav-icon" />,
  //   // items: [
  //   //   {
  //   //     component: CNavItem,
  //   //     name: 'Update Card',
  //   //     to: '/settings/update-card',
  //   //   },
  //   //   // {
  //   //   //   component: CNavItem,
  //   //   //   name: 'Create Orders',
  //   //   //   to: '/orders/create',
  //   //   // },
  //   //   // {
  //   //   //   component: CNavItem,
  //   //   //   name: 'Trash Orders',
  //   //   //   to: '/orders/trash',
  //   //   // },
  //   // ],
  // },
  {
    component: CNavTitle,
    name: 'Help & Support',
  },
  {
    component: CNavItem,
    name: 'Help & Support',
    to: '/help-and-support',
    target: '_blank',
    icon: <CIcon icon={cilExternalLink} customClassName="nav-icon" />,
    // badge: {
    //   color: 'info',
    //   text: 'NEW',
    // },
  },
  {
    component: CNavTitle,
    name: 'Safety',
  },
  {
    component: CNavItem,
    name: 'Community Guidelines',
    to: '/community-guidelines',
    target: '_blank',
    icon: <CIcon icon={cilExternalLink} customClassName="nav-icon" />,
    // badge: {
    //   color: 'info',
    //   text: 'NEW',
    // },
  },
  {
    component: CNavItem,
    name: 'Safety & Policy',
    to: '/safety-and-policy',
    target: '_blank',
    icon: <CIcon icon={cilExternalLink} customClassName="nav-icon" />,
    // badge: {
    //   color: 'info',
    //   text: 'NEW',
    // },
  },
  {
    component: CNavItem,
    name: 'Safety Tips',
    to: '/safety-tips',
    target: '_blank',
    icon: <CIcon icon={cilExternalLink} customClassName="nav-icon" />,
    // badge: {
    //   color: 'info',
    //   text: 'NEW',
    // },
  },
  {
    component: CNavTitle,
    name: 'Legal',
  },
  {
    component: CNavItem,
    name: 'Cookie Policy',
    to: '/cookie-policy',
    target: '_blank',
    icon: <CIcon icon={cilExternalLink} customClassName="nav-icon" />,
    // badge: {
    //   color: 'info',
    //   text: 'NEW',
    // },
  },
  {
    component: CNavItem,
    name: 'Privacy Policy',
    to: '/privacy-policy',
    target: '_blank',
    icon: <CIcon icon={cilExternalLink} customClassName="nav-icon" />,
    // badge: {
    //   color: 'info',
    //   text: 'NEW',
    // },
  },
  {
    component: CNavItem,
    name: 'Terms of Service',
    to: '/terms-of-service',
    target: '_blank',
    icon: <CIcon icon={cilExternalLink} customClassName="nav-icon" />,
    // badge: {
    //   color: 'info',
    //   text: 'NEW',
    // },
  },
  {
    component: CNavTitle,
    name: '',
  },

  {
    component: CNavItem,
    name: 'Logout',
    to: '/_login',
    onClick: async () => {
      await AuthHelpers.customerlogout()
    },
    icon: <CIcon icon={cilAccountLogout} customClassName="nav-icon" />,
  },
]

export default _customernav
