import React from 'react'

const Create = React.lazy(() => import('./create'))

const routes = [
  { path: '/cms/tags', name: 'Create Tags', element: Create },
  { path: '/cms/tags/:id/edit', name: 'Edit Tag', element: Create },
]

export default routes
