import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { CNavLink, CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react'
import { AppSidebarNav } from './AppSidebarNav'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'
import sidebarlogo from '../../assets/images/favicon.png'
// sidebar nav config
import navigation from '../../_customernav'
import CIcon from '@coreui/icons-react'
import jwt_decode from 'jwt-decode'
import Cookies from 'js-cookie'

import SafetyToolkit from '../customer/popup/safetyModel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeartCirclePlus, faShieldAlt } from '@fortawesome/free-solid-svg-icons'
import SafetyModel from '../customer/popup/safetyModel'

const AppSidebar = () => {
  const dispatch = useDispatch()
  const unfoldable = useSelector((state) => state.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => state.sidebarShow)
  const [showSafetyModel, setshowSafetyModel] = useState(false)

  const token = Cookies.get('customer_auth')
  var decoded = jwt_decode(token)
  dispatch({ type: 'set', profileDetails: decoded })

  const closeSafeyModel = () => {
    setshowSafetyModel(false)
  }

  return (
    <>
      <CSidebar
        position="fixed"
        unfoldable={unfoldable}
        visible={sidebarShow}
        onVisibleChange={(visible) => {
          dispatch({ type: 'set', sidebarShow: visible })
        }}
      >
        <CSidebarBrand className="d-none d-md-flex justify-content-between ps-3" to="/">
          {/* {process.env.REACT_APP_LOGO_SHOW == true ? (
          <img src={sidebarlogo} alt="logo" className="w-50" />
        ) : (
          <h5 dangerouslySetInnerHTML={{ __html: process.env.REACT_APP_NAME }}></h5>
        )} */}
          <CNavLink href="/#/user/dashboard">
            <img src={sidebarlogo} alt="logo" className="w-65" />
          </CNavLink>
          <div className="d-md-flex justify-content-between">
            <CNavLink href="/#/user/match">
              <div className="icon-bg me-3">
                <FontAwesomeIcon icon={faHeartCirclePlus} />
              </div>
            </CNavLink>
            <div className="icon-bg" onClick={() => setshowSafetyModel(true)}>
              <FontAwesomeIcon icon={faShieldAlt} />
            </div>
          </div>
        </CSidebarBrand>
        <CSidebarNav>
          <SimpleBar>
            <AppSidebarNav items={navigation} />
          </SimpleBar>
        </CSidebarNav>
        {/* <CSidebarToggler
          className="d-none d-lg-flex"
          onClick={() => dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })}
        /> */}
      </CSidebar>
      <SafetyModel visible={showSafetyModel} closeSafeyModel={closeSafeyModel} />
    </>
  )
}

export default React.memo(AppSidebar)
