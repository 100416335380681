import Cookies from 'js-cookie'
import BasicProvider from 'src/constants/BasicProvider'
import jwt_decode from 'jwt-decode'

class AuthHelpers {
  static async login(formdata, navigate, dispatch) {
    try {
      const response = await new BasicProvider('auth/signin', dispatch).postRequest(formdata)
      //console.log(response)
      if (response.data.token) {
        Cookies.set('auth', response.data.token, {
          expires: new Date(response.data.expires_in),
          domain: process.env.REACT_APP_URL,
          sameSite: 'strict',
        })
        dispatch({ type: 'set', isLogin: true })
        // dispatch({ type: 'setUserData', ...authData })
        navigate('/admin/dashboard')
        window.location.reload(false)
      }
    } catch (error) {
      console.error(error)
    }
  }
  static async customerlogin(formdata, navigate, dispatch) {
    try {
      const response = await new BasicProvider('public/customers/login', dispatch).postRequest(
        formdata,
      )
      //console.log(response)
      if (response.data.token) {
        Cookies.set('customer_auth', response.data.token, {
          expires: new Date(response.data.expires_in),
          domain: process.env.REACT_APP_URL,
          sameSite: 'strict',
        })
        dispatch({ type: 'set', isLogin: true })
        // dispatch({ type: 'setUserData', ...authData })
        navigate('/user/dashboard')
        window.location.reload(false)
      }
    } catch (error) {
      console.error(error)
    }
  }

  static async logout(token) {
    try {
      // //console.log(id)
      Cookies.remove('auth', { domain: process.env.REACT_APP_URL })
      Cookies.remove('userId', { domain: process.env.REACT_APP_URL })
      await new BasicProvider('auth/signout').postRequest({ token: token })
    } catch (error) {
      console.error(error)
    }
  }
  static async customerlogout(token) {
    try {
      // //console.log(id)
      Cookies.remove('customer_auth', { domain: process.env.REACT_APP_URL })
      Cookies.remove('userId', { domain: process.env.REACT_APP_URL })
      // await new BasicProvider('auth/signout').postRequest({ token: token })
    } catch (error) {
      console.error(error)
    }
  }
}
export default AuthHelpers
