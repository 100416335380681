import React from 'react'

const match = React.lazy(() => import('./match'))

const routes = [
  { path: '/user/match', name: 'Match', element: match },

]

export default routes
