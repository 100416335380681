import React from 'react'

const Create = React.lazy(() => import('./create'))

const routes = [
  { path: '/cms/categories', name: 'Create Categories', element: Create },
  { path: '/cms/categories/:id/edit', name: 'Edit Blog', element: Create },
]

export default routes
