import React, { Component, Suspense } from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate, HashRouter } from 'react-router-dom'
import './scss/style.scss'
import ResetPassword from './views/pages/auth/resetPassword/sendResetPassword'
import ForgotPassword from './views/pages/customer-auth/forgot-password'

import SetPassword from './views/pages/customer-auth/setPassword'
import CustomerSetPassword from './views/pages/customer-auth/setPassword'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Cookies from 'js-cookie'
import jwt_decode from 'jwt-decode'
import CustomerLayout from './layout/CustomerLayout'
// import  from './views/pages/customer-auth/verifyEmail'

const loading = (
  <div className="pt-3 text-center text-light">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const OTPVerificationForm = React.lazy(() => import('./views/pages/auth/register/Verify_Otp'))
const Login = React.lazy(() => import('./views/pages/auth/login/Login'))
const CustomerLogin = React.lazy(() => import('./views/pages/customer-auth/login'))
const VerifyEmail = React.lazy(() => import('./views/pages/customer-auth/verifyEmail'))
const VerifyPassword = React.lazy(() => import('./views/pages/customer-auth/verifyPasswordToken'))

const Register = React.lazy(() => import('./views/pages/auth/register/Register'))
const CustomerRegister = React.lazy(() => import('./views/pages/customer-auth/register'))
const CreateAccount = React.lazy(() => import('./views/pages/customer-auth/createaccount'))
const HelpSupport = React.lazy(() => import('./views/pages/customer-auth/helpandsupport'))

const CommunityGuidelines = React.lazy(() =>
  import('./views/pages/customer-auth/community-guidelines'),
)
const AllMessage = React.lazy(() => import('./views/customer-backend/message/messages'))

// const SafetyandPolicy = React.lazy(() => import('./views/pages/customer-auth/safety-policy'))
// const SafetyTips = React.lazy(() => import('./views/pages/customer-auth/safety-tips'))
// const CookiePolicy = React.lazy(() => import('./views/pages/customer-auth/cookie-policy'))
// const PrivacyPolicy = React.lazy(() => import('./views/pages/customer-auth/privacy-policy'))
// const Terms = React.lazy(() => import('./views/pages/customer-auth/terms-of-service'))

const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

import socket from './constants/socket'

class App extends Component {
  constructor(props) {
    super(props)
    this.decodedData = {
      type: 'customer',
    }
    this.token = null
    const allCookies = Cookies.get()
    const cookieNames = Object.keys(allCookies)
    let cookieKey = null

    for (const key of cookieNames) {
      if (key === 'auth' || key === 'customer_auth') {
        cookieKey = key
        break
      }
    }

    if (cookieKey) {
      this.token = Cookies.get(cookieKey)
      if (this.token) {
        this.decodedData = jwt_decode(this.token)
      }
    }
  }

  componentDidMount() {
    if (this.decodedData != undefined && this.decodedData.id != undefined) {
      socket.on('connect', () => {
        console.log('Connected to server')
        socket.emit('register_user', this.decodedData.id)
      })
    }
  }

  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            {/* <Route
              exact
              path="/register/enter-otp"
              name="Verify-Otp"
              element={<OTPVerificationForm></OTPVerificationForm>}
            ></Route> */}
            {
              <Route
                exact
                path="/admin/login"
                name="Login Page"
                element={
                  this.token && this.decodedData.type == 'admin' ? (
                    <Navigate to="/admin/dashboard" />
                  ) : (
                    <Login />
                  )
                }
              />
            }

            <Route
              exact
              path="/login"
              name="Customer Login Page"
              element={
                this.token && this.decodedData.type == 'customer' ? (
                  <Navigate to="/user/dashboard" />
                ) : (
                  <CustomerLogin />
                )
              }
            />

            {/* <Route exact path="/register" name="Register Page" element={<Register />} /> */}
            <Route
              exact
              path="/register"
              name="Customer Register Page"
              element={<CustomerRegister />}
            />

            <Route exact path="/verify-email" name="verify email" element={<VerifyEmail />} />

            <Route
              exact
              path="/verify-password"
              name="verify password"
              element={<VerifyPassword />}
            />

            <Route
              exact
              path="/create-account/:id"
              name="Customer Register Page"
              element={
              <CreateAccount />}
            />

            <Route
              exact
              path="/help-and-support"
              name="Help & Suport Page"
              element={<HelpSupport />}
            />

            <Route exact path="/_login" name="Customer Login Page" element={<CustomerLogin />} />

            <Route exact path="/_admin/login" name="Customer Login Page" element={<Login />} />

            {/* <Route
              exact
              path="/community-guidelines"
              name="Community Guidelines Page"
              element={<CommunityGuidelines />}
            /> */}

            <Route path="/message" element={<AllMessage />} />

            {/* <Route
              exact
              path="/safety-and-policy"
              name="Safety and Policy Page"
              element={<SafetyandPolicy />}
            />
            <Route exact path="/safety-tips" name="Safety Tips Page" element={<SafetyTips />} />
            <Route
              exact
              path="/cookie-policy"
              name="Cookie Policy Page"
              element={<CookiePolicy />}
            />
            <Route
              exact
              path="/privacy-policy"
              name="Privacy Policy Page"
              element={<PrivacyPolicy />}
            />
            <Route
              exact
              path="/terms-of-service"
              name="terms of Service Page"
              element={<Terms />}
            /> */}

            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route exact path="/reset/password" name="Reset Password" element={<ResetPassword />} />

            <Route
              exact
              path="/forgot-password"
              name="Reset Password"
              element={<ForgotPassword />}
            />
            <Route
              exact
              path="/set/password/:id"
              name="Customer Reset Password"
              element={<CustomerSetPassword />}
            />

            <Route path="/:slug" element={<CommunityGuidelines />} />

            {this.decodedData.type == 'customer' ? (
              <Route
                path="*"
                name="Home"
                element={this.token && this.decodedData ? <CustomerLayout /> : <CustomerLogin />}
              />
            ) : (
              <Route
                path="*"
                name="Home"
                element={this.token && this.decodedData ? <DefaultLayout /> : <Login />}
              />
            )}

            {/* <Route path="*" name="Home" element={token ? <DefaultLayout /> : <Login />} /> */}
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}

App.propTypes = {
  isLogin: PropTypes.any,
}

function mapStateToProps(state) {
  const allCookies = Cookies.get()
  const cookieNames = Object.keys(allCookies)
  let cookieKey = null
  // var token = Cookies.get(cookieNames[0])
  for (const key of cookieNames) {
    if (key === 'auth' || key === 'customer_auth') {
      cookieKey = key
      break // Exit the loop once a match is found
    }
  }
  if (cookieKey) {
    var token = Cookies.get(cookieKey)
  }
  var isLogin = state.isLogin
  if ((token !== null || token !== '') && token !== undefined && isLogin === undefined) {
    isLogin = true
  }
  return {
    token,
  }
}

// export default App

export default connect(mapStateToProps)(App)
