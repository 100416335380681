import React from 'react'

const All = React.lazy(() => import('./all'))
const Create = React.lazy(() => import('./create'))
// const Trash = React.lazy(() => import('./trash'))

const routes = [
  { path: '/cms/navigations', name: 'Navigation', element: All },
  { path: '/cms/navigation/create', name: 'Create Navigation', element: Create },
  { path: '/cms/navigation/edit', name: 'Edit Navigation', element: Create },
  //   { path: '/cms/navigation/trash', name: 'Trash Navigation', element: Trash },
]
  
export default routes
