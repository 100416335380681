import React from 'react'
import {
    CButton,
    CCard,
    CCardBody,
    CCol,
    CContainer,
    CFormCheck,
    CFormInput,
    CFormLabel,
    CInputGroup,
    CInputGroupText,
    CRow,
    CModal,
    CModalHeader,
    CModalTitle,
    CModalBody,
    CModalFooter,
    CCardImage,
    CFormTextarea,
  } from '@coreui/react'
  import { faCheck } from '@fortawesome/free-solid-svg-icons'
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
  import Tick from '../../../assets/images/tick_write.png'
  
function reportSuccessfull(props) {
    const { closeModel, CloseSuccessModel } = props
  return (
    <>
      <div className="more_then_mates_Customer ">
        <div className="successful_model">
          <CModal
            alignment="center"
            scrollable
            visible={props.visible}
            onClose={CloseSuccessModel}
            aria-labelledby="VerticallyCenteredScrollableExample"
            className="model_show customer-panel-card successful_model"
          >
            <CModalHeader className="pb-0"></CModalHeader>
            <CModalBody className="b-0 pb-5">
              <div className="success_model mx-2 my-3 py-2 text-center">
                <div className=" p-3 white d-flex justify-content-center align-items-center">
                  <div className="check me-1">
                    {/* <FontAwesomeIcon icon={faCheck} />{' '} */}
                    <img src={Tick} alt="successful" />
                  </div>{' '}
                </div>
                <div className="white">
                  <h6 className="mb-0">User Reported Successful  </h6> 
                </div>
              </div>
            </CModalBody>
          </CModal>
        </div>
      </div>
    </>
  )
}

export default reportSuccessfull