import React from 'react'

const location = React.lazy(() => import('./location'))



const routes = [
  { path: '/user/location', name: 'Location', element: location },
 
]

export default routes
