import React from 'react'

const All = React.lazy(() => import('./all'))
const Create = React.lazy(() => import('./create'))
const Trash = React.lazy(() => import('./trash'))

const routes = [
  { path: '/cms/pages', name: 'Pages', element: All },
  { path: '/cms/page/create', name: 'Create Page', element: Create },
  { path: '/cms/page/:id/edit', name: 'Edit Pages', element: Create },
  { path: '/cms/page/trash', name: 'Trash Pages', element: Trash },
]

export default routes
