import React, { useRef, useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CInputGroupText,
  CRow,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CCardImage,
  CCloseButton,
} from '@coreui/react'

import 'react-datepicker/dist/react-datepicker.css'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFlag, faFontAwesomeFlag, faShield } from '@fortawesome/free-solid-svg-icons'
import ReportSomeone from './reportsomone'
import { useNavigate } from 'react-router-dom'
import { cilArrowRight, cilChevronRight } from '@coreui/icons'
import CIcon from '@coreui/icons-react'

const SafetyModel = (props) => {
  const navigate = useNavigate()
  const [reportModel, setReportModel] = useState(false)
 

  const getModel = () => {
    props.closeSafeyModel()
    setReportModel(!reportModel)
  }
  const CloseReportModel = () => {
    setReportModel(false)
  }

  

  return (
    <>
      <div className="more_then_mates_Customer ">
        <CModal
          alignment="center"
          scrollable
          visible={props.visible}
          onClose={props.closeSafeyModel}
          aria-labelledby="VerticallyCenteredScrollableExample"
          className="model_show"
        >
          <CModalHeader className="bg-theme pb-0"></CModalHeader>
          <CModalBody className="bg-theme p-0 edit_profile">
            <h4 className="white text-center pb-2 mb-0">Safety Toolkit</h4>

            <div className="bb bt p-3">
              <CButton className="safety_tool bg-transparent p-0" onClick={getModel}>
                <FontAwesomeIcon icon={faFlag} className="primary fs-20 ms-3 me-2" />
                <span className="title">REPORT</span>
              </CButton>
            </div>
            <div className="p-3">
              <CButton className="safety_tool bg-transparent p-0"  onClick={() => navigate(`/safety-and-policy`)}>
                <FontAwesomeIcon icon={faShield} className="safety fs-20 ms-3 me-2" />
                <span className="title">SAFETY TIPS</span>
              </CButton>
         
            </div>
           
          </CModalBody>
        </CModal>
      </div>
      <ReportSomeone visible={reportModel} CloseReportModel={CloseReportModel} />
    </>
  )
}
export default SafetyModel
