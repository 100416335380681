import React from 'react'
import { AppContent, AppSidebar, AppFooter, AppHeader } from '../components/customerindex'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'

const initialOptions = {
  clientId: process.env.REACT_APP_CLIENT_ID,
  currency: process.env.REACT_APP_CURRENCY,
  debug: false,
  intent: 'capture',
}
// console.log(initialOptions);

const CustomerLayout = () => {
  // const module = useSelector((state) => state.module)

  return (
    <div className="customer-panel">
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1">
          <PayPalScriptProvider options={initialOptions}>
            <AppContent />
          </PayPalScriptProvider>

        </div>
        <AppFooter />
      </div>
    </div>
  )
}

export default CustomerLayout
