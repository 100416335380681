import React from 'react'

const Create = React.lazy(() => import('./createStatus'))

const routes = [
  { path: '/cms/status', name: 'Create Status', element: Create },
  { path: '/cms/status/:id/edit', name: 'Edit Tag', element: Create },
]

export default routes
