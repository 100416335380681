import React from 'react'

const All = React.lazy(() => import('./all'))



const routes = [
  { path: '/user/blockeduser', name: 'Blocked User', element: All },
 
]

export default routes
