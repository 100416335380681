import React, { useEffect, useRef, useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CInputGroupText,
  CRow,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CCardImage,
  CFormTextarea,
} from '@coreui/react'

import 'react-datepicker/dist/react-datepicker.css'
import { faGraduationCap, faShield } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CIcon from '@coreui/icons-react'
import { useNavigate } from 'react-router-dom'

import { cilArrowRight, cilCheck, cilChevronRight } from '@coreui/icons'
import Profile from '../../../assets/images/profilepic.jpg'
import BasicProvider from 'src/constants/BasicProvider'
import ReportSuccessfull from './reportSuccessfull'

const ReportSomeone = (props) => {
  const { activeUser } = props

  // console.log('Lastuser>>', activeUser)

  const navigate = useNavigate()
  const [successModel, setsuccessModel] = useState(false)
  const [report, setReport] = useState({
    message: '',
    to_customer_id: '',
  })

  useEffect(() => {
    setReport({ ...report, to_customer_id: activeUser?._id || activeUser?.id })
  }, [activeUser])

  const handleSubmit = async () => {
    try {
      const response = await new BasicProvider(`public/reports/create`).postRequest(report)
      // console.log('ReportRes', report)
    } catch (error) {
      console.log(error)
    }
    setsuccessModel(true)
    props.CloseReportModel()
  }

  const handleSuccessModel = () => {
    setsuccessModel(false)
  }

  return (
    <>
      <div className="more_then_mates_Customer ">
        <CModal
          alignment="center"
          scrollable
          visible={props.visible}
          onClose={props.CloseReportModel}
          aria-labelledby="VerticallyCenteredScrollableExample"
          className="model_show Custome_Model_btn"
        >
          <CModalBody className="bg-theme b-0 ">
            <div className="d-flex justify-content-between">
              <div></div>

              <CButton className="bg-transparent primary f-6" onClick={props.CloseReportModel}>
                Cancel
              </CButton>
            </div>

            <div className="text-center disable-hover my-3">
              <h5 className="white">Report </h5>
            </div>
            <div className="my-3 p-3">
              <p className="white text-center mb-0">Please let us know who you’d like to report.</p>
            </div>

            <div className="report_user_Select mx-2 my-3 py-2">
              {/* <CButton className={`report_img ${noMatched ? 'active' : ''}`} onClick={getMatched}>
                <CRow className="align-items-center">
                  <CCol md={3}>
                    <div className={`default_img w-70 m-auto ${noMatched ? 'img-active' : ''}`}>
                      <img src={Profile} className={`w-100 ${noMatched ? 'img-active' : ''}`} />
                    </div>
                  </CCol>
                  <CCol md={9}>
                    <div className="whtie text-start bb d-flex justify-content-between ">
                      <p className="mb-4"> Someone I’m no longer matched with</p>

                      {noMatched && <CIcon icon={cilCheck} className="me-2 primary" />}
                    </div>
                  </CCol>
                </CRow>
              </CButton> */}
              <CFormLabel className="white">Leave a comment here</CFormLabel>
              <CFormTextarea
                id="floatingTextarea"
                onChange={(event) => {
                  setReport({ ...report, message: event.target.value })
                }}
              ></CFormTextarea>
            </div>

            <div
              className="d-flex justify-content-between m-3 py-3 white "
              onClick={() => navigate(`/safety-and-policy`)}
            >
              <div className=" d-flex">
                <FontAwesomeIcon icon={faGraduationCap} className="me-3 mt-1" />
                <div>
                  <span className="f-6">Explore Safety Centre</span>
                  <p className="mb-0">Learn more about the reporting process</p>
                </div>
              </div>
              <CIcon icon={cilChevronRight}></CIcon>
            </div>
            <div className="save text-center">
              <CButton className="btn-default w-100" onClick={handleSubmit}>
                Next
              </CButton>
            </div>
          </CModalBody>
        </CModal>
      </div>
      <ReportSuccessfull visible={successModel} CloseSuccessModel={handleSuccessModel} />
    </>
  )
}
export default ReportSomeone
